import { graphql, Link, navigate } from "gatsby";
import React, { useCallback } from "react";
import Layout from "../components/layout";

function extractPathNames(data: any) {
  return data.allSitePage.edges.map(
    (edge: any) => edge.node.path.split("/").reverse()[0]
  );
}

function randomElement(items: any[]) {
  return items[Math.floor(Math.random() * items.length)];
}

export default function Home({ data }) {
  const pathNames = extractPathNames(data);

  const handleExploreRandomArtistClick = useCallback(() => {
    const pathName = randomElement(pathNames);
    navigate(`/artist/${pathName}`);
  }, []);

  return (
    <Layout>
      <button onClick={handleExploreRandomArtistClick}>
        Explore Random Artist
      </button>
      <ul>
        {pathNames.map((pathName: string) => (
          <li key={pathName}>
            <Link to={`/artist/${pathName}`}>{pathName}</Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
}

export const query = graphql`
  query ArtistPaths {
    allSitePage(
      filter: { pluginCreator: { name: { eq: "gatsby-discogs-plugin" } } }
    ) {
      totalCount
      edges {
        node {
          path
        }
      }
    }
  }
`;
